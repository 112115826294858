import React from "react";

import { Accordion, Box, Flex, Select } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { NoElementsText } from "common/components/NoElementsText";
import { SectionHeader } from "common/components/SectionHeader";
import { defaultValuesSistemas } from "components/Portfolio/defaultValues";
import { THUNK_STATE } from "config/types";
import { displayWhenPrint } from "helpers/displayWhenPrint";
import { expandAllItems } from "helpers/expandedItems";
import { formsUrl } from "helpers/formsUrl";
import { sistemaLabel } from "helpers/tableLabels";
import { capitalizeFirstLetter } from "helpers/text";
import { getNecListThunk } from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { fechaPrimerEvento } from "../utils/fechasIte";
import { titloIte } from "../utils/tituloIte";
import { TableHeader } from "./Header";
import { TableRow } from "./Row";
import { NEC } from "./types";

type IProps = {
	print?: boolean;
};

export const TablaConservacion: React.FC<IProps> = (props) => {
	const { print } = props;
	const { iteId } = useParams();
	const dispatch = useAppDispatch();
	const iteSelected = useAppSelector((state) => state.ites.iteSelected.data);
	const iteListItemSelected = useAppSelector((state) => state.ites.iteList.data.find((it) => it.id === iteId));

	const { data: nec, thunkState } = useAppSelector((state) => state.ites.nec);

	const [filteredNec, setFilteredNec] = React.useState<NEC[]>(nec);

	React.useEffect(() => {
		if (!iteId) return;
		if (thunkState !== THUNK_STATE.FULFILLED) dispatch(getNecListThunk({ iteId }));
	}, [iteId, thunkState]);

	React.useEffect(() => {
		setFilteredNec(nec);
	}, [nec]);

	const sistemas = [...new Set(nec.map((nec) => nec.sistema))];
	const indices = [...new Set(nec.map((nec) => nec.indice))];

	const indicesPorSistemas = sistemas.map((sistema) => {
		const index = nec.filter((nec) => nec.sistema === sistema).map((nec) => nec.indice);
		const highestIndex = index.reduce((a, b) => (a > b ? a : b));
		return { label: sistema, value: highestIndex };
	});

	const handleFilter = (data: NEC[], filter: string, field: string) => {
		const filtered = data.filter((nec) => nec[field] === filter);
		filter ? setFilteredNec(filtered) : setFilteredNec(nec);
	};

	return (
		<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem" overflow="hidden" overflowY="auto">
			<SectionHeader
				formLink={formsUrl(iteSelected?.ite.record_ec_id)}
				ite={
					iteListItemSelected
						? titloIte(fechaPrimerEvento(iteListItemSelected).toJSDate(), iteListItemSelected.tipo, "short")
						: "ITE"
				}
				tableTitle="Estado de Conservación"
				print={print}
			/>
			{filteredNec.length > 0 && (
				<Flex align="center" mb="1rem" flexDirection="row" m="1rem" sx={{ display: displayWhenPrint(print) }}>
					<Box w="50%" mr="2rem">
						<Select
							onChange={(e) => handleFilter(nec, e.target.value, "sistema")}
							placeholder={`Todos los sistemas ${iteSelected?.ite.nec.indice}` || "Todos los sistemas"}
							size="md"
							fontSize=".75rem"
							borderRadius="md"
						>
							{indicesPorSistemas.map((sistema, i) => (
								<option key={i} value={sistema.label}>
									{`${capitalizeFirstLetter(
										sistemaLabel(
											defaultValuesSistemas.find((it) => it.value === sistema.label)?.label ?? sistema.label,
										),
									)} ${sistema.value}`}
								</option>
							))}
						</Select>
					</Box>
					<Box w="50%">
						<Select
							onChange={(e) => handleFilter(nec, e.target.value, "indice")}
							placeholder="Todos los índice"
							size="md"
							fontSize=".75rem"
							borderRadius="md"
						>
							{indices.sort().map((indice, i) => (
								<option key={i} value={indice}>
									{`Índice ${indice}`}
								</option>
							))}
						</Select>
					</Box>
				</Flex>
			)}
			<TableHeader />
			<Box mr="1rem">
				<Accordion allowToggle h="100%" index={print ? expandAllItems(filteredNec) : undefined}>
					{filteredNec.length > 0 ? (
						filteredNec.map((data, i) => <TableRow key={i} nec={data} print={print} />)
					) : (
						<NoElementsText />
					)}
				</Accordion>
			</Box>
		</Box>
	);
};
