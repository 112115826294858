import { AxiosRequestConfig } from "axios";

import { ActivoCompletoInput, ActivoCompletoOutput, PortfolioStats } from "components/Portfolio/types/activos";
import { envConfig } from "config/env";
import { ApiPaginatedResponse } from "config/types";

import { apiClient, CustomAxiosRequest } from ".";

// 1. GET Info Activos
export type GetInfoActivosQueryParams = {
	estadoITE?: string;
	estadoLevantamiento?: string;
	estadoFichaInventario?: string;
	resultadoITE?: string;
	ambito?: string;
	especialidad?: string;
	uso?: string;
	relevancia?: string;
	tipologia?: string;
	empresa?: string;
	searchText?: string;
	fechaVisita?: string;
	fechaVisitaProxIte?: string;
	fechaEntregaIteFrom?: string;
	fechaEntregaIteTo?: string;
	fechaEntregaBimFrom?: string;
	fechaEntregaBimTo?: string;
	fechaCertificacionBimFrom?: string;
	fechaCertificacionBimTo?: string;
	fechaCertificacionIteFrom?: string;
	fechaCertificacionIteTo?: string;
	iteEntregada?: boolean;
	iteCertificada?: boolean;
	bimEntregado?: boolean;
	bimCertificado?: boolean;
	page?: number;
	order?: string;
};

export const getInfoActivos: CustomAxiosRequest<
	GetInfoActivosQueryParams,
	ApiPaginatedResponse<ActivoCompletoOutput[]>
> = (params) => {
	const url = `activos/${envConfig.PROJECT_REF}`;
	const config: AxiosRequestConfig<ActivoCompletoOutput[]> = {
		method: "GET",
		url,
		params: params,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// 2. GET Info Stats
export type GetPortfolioStatsQueryParams = {
	estadoITE?: string;
	estadoLevantamiento?: string;
	estadoFichaInventario?: string;
	resultadoITE?: string;
	ambito?: string;
	especialidad?: string;
	uso?: string;
	relevancia?: string;
	tipologia?: string;
	empresa?: string;
	fechaVisita?: string;
	fechaVisitaProxIte?: string;
	fechaEntregaIteFrom?: string;
	fechaEntregaIteTo?: string;
	fechaEntregaBimFrom?: string;
	fechaEntregaBimTo?: string;
	fechaCertificacionBimFrom?: string;
	fechaCertificacionBimTo?: string;
	fechaCertificacionIteFrom?: string;
	fechaCertificacionIteTo?: string;
	iteEntregada?: boolean;
	iteCertificada?: boolean;
	bimEntregado?: boolean;
	bimCertificado?: boolean;
};

export const getPortfolioStats: CustomAxiosRequest<GetPortfolioStatsQueryParams, PortfolioStats> = (params) => {
	const url = `activos/${envConfig.PROJECT_REF}/stats`;
	const config: AxiosRequestConfig<PortfolioStats> = {
		method: "GET",
		url,
		params: params,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// 3. PUT Editar Activos
type putEditarActivoPropsType = {
	assetId: string;
	data: ActivoCompletoInput;
};

export const putEditarActivo: CustomAxiosRequest<putEditarActivoPropsType, ActivoCompletoOutput> = (props) => {
	const { assetId, data } = props;
	const url = `activos/${envConfig.PROJECT_REF}/${assetId}/guardar`;
	const config: AxiosRequestConfig<ActivoCompletoInput> = {
		method: "PUT",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data,
	};
	return apiClient(config);
};

// 4. PUT Validar Activos
type putValidarActivoPropsType = {
	assetId: string;
	data: ActivoCompletoInput;
};

export const putValidarActivo: CustomAxiosRequest<putValidarActivoPropsType, ActivoCompletoOutput> = (props) => {
	const { assetId, data } = props;
	const url = `activos/${envConfig.PROJECT_REF}/${assetId}/validar`;
	const config: AxiosRequestConfig<ActivoCompletoInput> = {
		method: "PUT",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data,
	};
	return apiClient(config);
};

type putRechazarActivoPropsType = {
	assetId: string;
	comentario: string;
};

export const putRechazarActivo: CustomAxiosRequest<putRechazarActivoPropsType, ActivoCompletoOutput> = (props) => {
	const { assetId, comentario } = props;
	const url = `activos/${envConfig.PROJECT_REF}/${assetId}/rechazar`;
	const config: AxiosRequestConfig = {
		method: "PUT",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: { comentario: comentario },
	};
	return apiClient(config);
};

type postDuplicarActivoPropsType = {
	assetId: string;
	nombre: string;
};

export const postDuplicarActivo: CustomAxiosRequest<postDuplicarActivoPropsType, ActivoCompletoOutput> = (props) => {
	const { assetId, nombre } = props;
	const url = `activos/${envConfig.PROJECT_REF}/${assetId}/duplicar`;
	const config: AxiosRequestConfig = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: { nombre: nombre },
	};
	return apiClient(config);
};
// 5. POST Nuevo activo
type postNuevoActivoPropsType = {
	data: ActivoCompletoInput;
};

export const postNuevoActivo: CustomAxiosRequest<postNuevoActivoPropsType, ActivoCompletoOutput> = (props) => {
	const { data } = props;
	const url = `conf/assets`;
	const config: AxiosRequestConfig = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: {
			asset: {
				project_ref: envConfig.PROJECT_REF,
				name: data.nombre,
				location: `${data.direccion}, ${data.municipio}`,
				uses: data.usos,
			},
		},
	};
	return apiClient(config);
};

// 6. GET Nuevo excel
export const getExcel = () =>
	apiClient.get(`excel`, {
		params: { projectRef: envConfig.PROJECT_REF },
	});
