import React from "react";

import {
	Text,
	Box,
	Button,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalOverlay,
	Flex,
	useToast,
} from "@chakra-ui/react";

import { getExcel } from "api/portfolio";

import FullsizeSpinner from "../FullsizeSpinner";

interface IProps {
	isOpen: boolean;
	onClose: () => void;
}
type PropsType = IProps;

const DownloadExcelModal: React.FC<PropsType> = (props) => {
	const { isOpen, onClose } = props;
	const toast = useToast();

	const [isLoading, setIsLoading] = React.useState<boolean>(false);

	const formatNumber = (num: number) => {
		return num.toString().padStart(2, "0");
	};

	const downloadExcel = async () => {
		setIsLoading(true);
		await getExcel()
			.then((response) => {
				const bufferData = response.data.data;
				const arrayBuffer = Uint8Array.from(bufferData).buffer;
				const blob = new Blob([arrayBuffer], {
					type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
				});
				const url = window.URL.createObjectURL(blob);
				const link = document.createElement("a");
				const date = new Date();
				const filename = `${date.getFullYear()}_${formatNumber(date.getMonth() + 1)}_${formatNumber(
					date.getDate(),
				)}_${formatNumber(date.getHours())}h${formatNumber(date.getMinutes())}m${formatNumber(
					date.getSeconds(),
				)}s_activos-adif-ite`;

				link.href = url;
				link.download = `${filename}.xlsx`;
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
				window.URL.revokeObjectURL(url);
			})
			.catch(() => {
				toast({
					title: "Error al generar el excel. Si el error persiste, por favor contacte con el administrador",
					status: "error",
					duration: 3000,
					isClosable: true,
				});
			});
		setIsLoading(false);
		onClose();
	};

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="sm" closeOnOverlayClick={false}>
			<ModalOverlay />
			<ModalContent p="1rem">
				<ModalCloseButton />
				<ModalBody>
					<Flex paddingTop="1rem" alignItems="center" justifyContent="center">
						<Text fontWeight="bold" fontSize="body1">
							Descargar Excel
						</Text>{" "}
					</Flex>
					<Flex alignItems="center" justifyContent="center">
						<Text fontWeight="bold" fontSize="body1">
							con lista de activos
						</Text>
					</Flex>
					<Flex
						paddingTop="2.5rem"
						paddingBottom="2.5rem"
						h="100%"
						w="100%"
						alignItems="center"
						justifyContent="center"
					>
						{isLoading ? (
							<Box textAlign={"center"}>
								<FullsizeSpinner></FullsizeSpinner>
							</Box>
						) : (
							<Button
								bg="gray.400"
								size="md"
								borderRadius="5px"
								h="2rem"
								onClick={() => {
									downloadExcel();
								}}
							>
								<Text textStyle="body2" color="white">
									Descargar
								</Text>
							</Button>
						)}
					</Flex>
				</ModalBody>
			</ModalContent>
		</Modal>
	);
};

export default DownloadExcelModal;
