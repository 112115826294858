import { AxiosRequestConfig } from "axios";

import { NAE } from "components/ITE/TablaAccesibilidad/types";
import { NEC } from "components/ITE/TablaConservacion/types";
import { INVOutput } from "components/ITE/TablaInventario/types";
import { NCN } from "components/ITE/TablaNormativa/types";
import { ITEListOutput, ITEOutput } from "components/ITE/types/ite";
import { ReportOutput } from "components/ITE/types/report";
import { ApiPaginatedResponse } from "config/types";

import { apiClient, CustomAxiosRequest } from "./";

// GET Ite/Ite
export type GetIteListQueryParams = {
	assetId?: string;
	page?: number;
	order?: string;
};

export const getIteList: CustomAxiosRequest<GetIteListQueryParams, ApiPaginatedResponse<ITEListOutput[]>> = (
	params,
) => {
	const url = `ite/ite/`;
	const config: AxiosRequestConfig<ITEListOutput[]> = {
		method: "GET",
		url,
		params: params,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// GET Ite/Ite/${Id}
type GetIteIdPropsType = {
	id: string;
};

export const getIteById: CustomAxiosRequest<GetIteIdPropsType, ITEOutput> = (props) => {
	const { id } = props;
	const url = `ite/ite/${id}`;
	const config: AxiosRequestConfig = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// GET Ite/Ite/${Id}
type GetReportsByITEIdPropsType = {
	iteId: string;
};
export const getReportsByITEId: CustomAxiosRequest<GetReportsByITEIdPropsType, ReportOutput[]> = (props) => {
	const { iteId } = props;
	const url = `ite/reports/${iteId}`;
	const config: AxiosRequestConfig = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// GET Ite/inv/<iteId>
export type GetInvQueryProps = {
	iteId: string;
};

export const getDatosInventario: CustomAxiosRequest<GetInvQueryProps, INVOutput> = (props) => {
	const { iteId } = props;
	const url = `ite/inv/${iteId}`;
	const config: AxiosRequestConfig<INVOutput[]> = {
		method: "GET",
		url,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// GET IDE/nec/<iteId>
export type GetNecQueryProps = {
	iteId?: string;
	categoria?: number;
	normativa?: string;
	page?: number;
	order?: string;
};

export const getNecList: CustomAxiosRequest<GetNecQueryProps, ApiPaginatedResponse<NEC[]>> = (props) => {
	const { iteId, ...queryParams } = props;
	const url = `ite/nec/${iteId}`;
	const config: AxiosRequestConfig<ITEListOutput[]> = {
		method: "GET",
		url,
		params: queryParams,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// GET IDE/nee/<iteId>
export type GetNeeQueryProps = {
	iteId?: string;
	categoria?: number;
	indice?: string;
	page?: number;
	order?: string;
};

export const getNeeList = (props: GetNeeQueryProps) => {
	const { iteId, ...queryParams } = props;
	const url = `ite/nee/${iteId}`;
	const config: AxiosRequestConfig<ITEListOutput[]> = {
		method: "GET",
		url,
		params: queryParams,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// GET IDE/nae/<iteId>
export type GetNaeQueryProps = {
	iteId?: string;
	categoria?: number;
	normativa?: string;
	page?: number;
	order?: string;
};

export const getNaeList: CustomAxiosRequest<GetNaeQueryProps, ApiPaginatedResponse<NAE[]>> = (props) => {
	const { iteId, ...queryParams } = props;
	const url = `ite/nae/${iteId}`;
	const config: AxiosRequestConfig<ITEListOutput[]> = {
		method: "GET",
		url,
		params: queryParams,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// GET IDE/ncn/<iteId>
export type GetNcnQueryProps = {
	iteId?: string;
	categoria?: number;
	normativa?: string;
	page?: number;
	order?: string;
};

export const getNcnList: CustomAxiosRequest<GetNcnQueryProps, ApiPaginatedResponse<NCN[]>> = (props) => {
	const { iteId, ...queryParams } = props;
	const url = `ite/ncn/${iteId}`;
	const config: AxiosRequestConfig<ITEListOutput[]> = {
		method: "GET",
		url,
		params: queryParams,
		headers: {
			"Content-Type": "application/json",
		},
	};
	return apiClient(config);
};

// PUT ITE/validar
export type ValidarProps = {
	id: string;
	comentario: string;
	fechaNuevaITE?: string;
};

export const putIteValidar: CustomAxiosRequest<ValidarProps, ITEOutput> = (data) => {
	const url = `ite/ite/validar`;
	const config: AxiosRequestConfig<ValidarProps> = {
		method: "PUT",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};
	return apiClient(config);
};

// PUT ITE/rechazar
export type RechazarProps = {
	id: string;
	comentario: string;
};

export const putIteRechazar: CustomAxiosRequest<RechazarProps, ITEOutput> = (data) => {
	const url = `ite/ite/rechazar`;
	const config: AxiosRequestConfig<RechazarProps> = {
		method: "PUT",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};
	return apiClient(config);
};

// PUT ITE/reprogramar
export type ReprogramarProps = {
	id: string;
	fecha: string;
	esReprogramacion?: boolean;
};

export const putIteReprogramar: CustomAxiosRequest<ReprogramarProps, ITEOutput> = (data) => {
	const url = `ite/ite/reprogramar`;
	const config: AxiosRequestConfig<ReprogramarProps> = {
		method: "PUT",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};
	return apiClient(config);
};

// POST ITE/publicar
export type publicarSegunEstadoProps = {
	assetId: string;
	fecha: string;
};

export const postPublicarIteSegunEstado: CustomAxiosRequest<publicarSegunEstadoProps, ITEOutput> = (data) => {
	const url = `ite/ite/segunEstado`;
	const config: AxiosRequestConfig<publicarSegunEstadoProps> = {
		method: "POST",
		url,
		headers: {
			"Content-Type": "application/json",
		},
		data: data,
	};
	return apiClient(config);
};
