import React from "react";

import { Accordion, Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

import { NoElementsText } from "common/components/NoElementsText";
import { SectionHeader } from "common/components/SectionHeader";
import { THUNK_STATE } from "config/types";
import { expandAllItems } from "helpers/expandedItems";
import { formsUrl } from "helpers/formsUrl";
import { getDatosInventarioThunk } from "store/ITE/thunks";
import { useAppDispatch, useAppSelector } from "store/store";

import { fechaPrimerEvento } from "../utils/fechasIte";
import { titloIte } from "../utils/tituloIte";
import { TableHeader } from "./Header";
import { MiddleSectionInfo } from "./MiddleSectionInfo";
import { TableRow } from "./Row";

type IProps = {
	print?: boolean;
};

export const TablaInventario: React.FC<IProps> = (props) => {
	const { print } = props;
	const { iteId } = useParams();
	const dispatch = useAppDispatch();
	const { data: datosInventario, thunkState } = useAppSelector((state) => state.ites.inv);
	const iteListItemSelected = useAppSelector((state) => state.ites.iteList.data.find((it) => it.id === iteId));

	React.useEffect(() => {
		if (!iteId) return;
		if (thunkState !== THUNK_STATE.FULFILLED) dispatch(getDatosInventarioThunk({ iteId }));
	}, [iteId, thunkState]);

	if (!datosInventario) return <React.Fragment />;
	return (
		<Box p="1rem" rounded="md" boxShadow="sm" bgColor="white" pb="2rem" overflow="hidden" overflowY="auto">
			<SectionHeader
				formLink={formsUrl(datosInventario.contexto.record_id)}
				ite={
					iteListItemSelected
						? titloIte(fechaPrimerEvento(iteListItemSelected).toJSDate(), iteListItemSelected.tipo, "short")
						: "ITE"
				}
				tableTitle="Ficha de Inventario"
				print={print}
			/>
			<MiddleSectionInfo />
			{datosInventario.items.length > 0 && <TableHeader />}
			<Box mr="1rem">
				<Accordion allowToggle h="100%" index={print ? expandAllItems(datosInventario.items) : undefined}>
					{datosInventario.items.length > 0 ? (
						datosInventario.items.map((data, i) => <TableRow key={i} sistema={data} print={print} />)
					) : (
						<NoElementsText />
					)}
				</Accordion>
			</Box>
		</Box>
	);
};
