import React from "react";

import { Box, Flex, Grid, GridItem, Icon } from "@chakra-ui/react";
import { BsCheckCircle, BsChevronRight, BsExclamationTriangle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import { PortfolioInfoLine } from "common/components/PortfolioInfoLine";
import { defaultValuesTipologias, defaultValuesUsos } from "components/Portfolio/defaultValues";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
interface IProps {
	asset: ActivoCompletoOutput;
}

export const TipologiaRow: React.FC<IProps> = (props) => {
	const { asset } = props;
	const navigate = useNavigate();
	const { pathname } = useLocation();

	return (
		<Box
			rounded="md"
			bgColor="white"
			mt=".5rem"
			pl="1rem"
			h="2.5rem"
			onClick={() => navigate(`/activo/${asset.id}/portada/${asset.ite_id}`, { state: { from: pathname } })}
			_hover={{ cursor: "pointer", boxShadow: "sm" }}
		>
			<Flex height="100%" alignItems="center">
				<Grid templateColumns="repeat(16, 1fr)" w="100%" color="gray.800" alignItems="center">
					<GridItem colSpan={1} pt=".5rem" pl="1.5rem">
						{asset.por_validar === undefined || asset.por_validar ? (
							<Icon as={BsExclamationTriangle} color="red.500" fontSize="1.25rem" />
						) : (
							<Icon as={BsCheckCircle} color="green.500" fontSize="1.25rem" />
						)}
					</GridItem>
					<GridItem colSpan={4}>
						<PortfolioInfoLine value={asset.nombre} />
					</GridItem>
					<GridItem colSpan={2}>
						<PortfolioInfoLine value={asset.ambito} />
					</GridItem>
					{asset && (
						<React.Fragment>
							<GridItem colSpan={2}>
								<PortfolioInfoLine
									value={defaultValuesTipologias.find((it) => it.value === asset.tipologia)?.label ?? asset.tipologia}
								/>
							</GridItem>
							<GridItem colSpan={2}>
								<PortfolioInfoLine value={asset.agrupacion} />
							</GridItem>
							<GridItem colSpan={2}>
								<PortfolioInfoLine
									value={
										asset.uso_principal
											? [
													(defaultValuesUsos.find((it) => it.value === asset.uso_principal)?.label ??
														asset.uso_principal) + " (p.)",
													...(asset.usos?.map(
														(uso) => defaultValuesUsos.find((it) => it.value === uso)?.label ?? uso,
													) ?? []),
											  ]
											: asset.usos || []
									}
								/>
							</GridItem>
							<GridItem colSpan={2}>
								<PortfolioInfoLine value={asset.especialidades} />
							</GridItem>
							<GridItem colSpan={1}>
								<PortfolioInfoLine value={asset.relevancia} />
							</GridItem>
						</React.Fragment>
					)}
				</Grid>
				<Icon as={BsChevronRight} color="gray.500" width="1rem" height="1rem" mr="1rem" />
			</Flex>
		</Box>
	);
};
