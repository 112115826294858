import React from "react";

import { Button, Text, WrapItem } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

type IProps = {
	name: string;
	path: boolean;
};

export const TableButton: React.FC<IProps> = (props) => {
	const { name, path } = props;
	const navigate = useNavigate();

	const handleClick = () => {
		switch (name) {
			case "Inspección":
				navigate("/portfolio/inspeccion");
				break;
			case "Localización":
				navigate("/portfolio/localizacion");
				break;
			case "Tipología":
				navigate("/portfolio/tipologia");
				break;
			case "E. Conservación":
				navigate("/portfolio/nec");
				break;
			case "E. Energética":
				navigate("/portfolio/nee");
				break;
			case "Accesibilidad":
				navigate("/portfolio/nae");
				break;
			case "C. Normativa":
				navigate("/portfolio/ncn");
				break;
			case "Certificación":
				navigate("/portfolio/certificacion");
				break;
			default:
				navigate("/portfolio/inspeccion");
				break;
		}
	};

	return (
		<React.Fragment>
			<WrapItem>
				<Button
					size="md"
					borderRadius="5px"
					h="auto"
					minH="2rem"
					variant="outline"
					borderWidth="1.5px"
					borderColor={path ? "gray.500" : "gray.300"}
					_hover={path ? {} : { bgColor: "gray.100" }}
					onClick={() => handleClick()}
				>
					<Text textStyle="body3" color={path ? "gray.700" : "gray.400"}>
						{name}
					</Text>
				</Button>
			</WrapItem>
		</React.Fragment>
	);
};
