import React from "react";

import { Box, Flex, Grid, GridItem, Icon } from "@chakra-ui/react";
import { BsCheckCircle, BsChevronRight, BsExclamationTriangle } from "react-icons/bs";
import { useLocation, useNavigate } from "react-router-dom";

import { PortfolioInfoLine } from "common/components/PortfolioInfoLine";
import StatusIndicator from "common/components/StatusIndicator";
import { ActivoCompletoOutput } from "components/Portfolio/types/activos";
interface IProps {
	asset: ActivoCompletoOutput;
}

export const InspeccionRow: React.FC<IProps> = (props) => {
	const { asset } = props;
	const navigate = useNavigate();
	const { pathname } = useLocation();

	const getColorFechaVisita = (asset: ActivoCompletoOutput) => {
		if (asset.estado_ite === "Registrado") return "blue.400";
		else if (asset.fecha_visita_reprogramada) return "green.400";
		else return "inherit";
	};

	const getColorFechaProximaVisita = (asset: ActivoCompletoOutput) => {
		if (asset.fecha_visita_reprogramada_prox_ite) return "green.400";
		else return "inherit";
	};

	return (
		<Box
			p=".5rem 0"
			rounded="md"
			bgColor="white"
			mt=".5rem"
			pl="1rem"
			h="2.5rem"
			onClick={() => navigate(`/activo/${asset.id}/portada/${asset.ite_id}`, { state: { from: pathname } })}
			_hover={{ cursor: "pointer", boxShadow: "sm" }}
		>
			<Flex height="100%" alignItems="center">
				<Grid templateColumns="repeat(23, 1fr)" w="100%" color="gray.800" alignItems="center">
					<GridItem colSpan={2} pt=".5rem" pl="1.5rem">
						{asset.por_validar === undefined || asset.por_validar ? (
							<Icon as={BsExclamationTriangle} color="red.500" fontSize="1.25rem" />
						) : (
							<Icon as={BsCheckCircle} color="green.500" fontSize="1.25rem" />
						)}
					</GridItem>
					<GridItem data-testid="assetName" colSpan={5}>
						<PortfolioInfoLine value={asset.nombre} />
					</GridItem>
					{asset && (
						<React.Fragment>
							<GridItem colSpan={2}>
								<StatusIndicator estado={asset.estado_ite} />
							</GridItem>
							<GridItem colSpan={2}>
								<PortfolioInfoLine value={asset.resultado_ite} />
							</GridItem>
							<GridItem colSpan={2}>
								<PortfolioInfoLine value={asset.empresa} />
							</GridItem>
							<GridItem colSpan={3}>
								<PortfolioInfoLine value={asset.tecnico} />
							</GridItem>
							<GridItem colSpan={3}>
								<PortfolioInfoLine value={asset.autor} />
							</GridItem>
							<GridItem colSpan={2}>
								<PortfolioInfoLine
									value={new Date(
										asset.fecha_visita_reportada || asset.fecha_visita_reprogramada || asset.fecha_visita_programada,
									).toLocaleDateString("es-ES")}
									styleProps={{ color: getColorFechaVisita(asset) }}
								/>
							</GridItem>
							<GridItem colSpan={2}>
								{asset.fecha_visita_reprogramada_prox_ite || asset.fecha_visita_programada_prox_ite ? (
									<PortfolioInfoLine
										value={new Date(
											asset.fecha_visita_reprogramada_prox_ite || asset.fecha_visita_programada_prox_ite || "",
										).toLocaleDateString("es-ES")}
										styleProps={{ color: getColorFechaProximaVisita(asset) }}
									/>
								) : (
									<></>
								)}
							</GridItem>
						</React.Fragment>
					)}
				</Grid>
				<Icon as={BsChevronRight} color="gray.500" width="1rem" height="1rem" mr="1rem" />
			</Flex>
		</Box>
	);
};
